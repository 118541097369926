<template>
  <el-carousel trigger="click" class="swiper swiper-content" :height="height">
    <el-carousel-item>
      <div class="swiper-item swiper-item-1">
        <img ref="img" class="swiper-img" src="@/assets/images/swiper/swiperitem01.jpg" alt />
        <div class="item-text">
          <h1>APP跨平台开发</h1>
          <ul>
            <li>实现定制开发</li>
            <li>极致的页面交互体验</li>
          </ul>
        </div>
      </div>
    </el-carousel-item>
    <el-carousel-item>
      <div class="swiper-item swiper-item-2">
        <img ref="img1" class="swiper-img" src="@/assets/images/swiper/swiperitem03.jpg" alt />
        <div class="item-text">
          <h1>企业移动平台</h1>
          <ul>
            <li>最新的移动互联网+平台</li>
            <li>HTML5+CSS3主流技术</li>
          </ul>
        </div>
      </div>
    </el-carousel-item>
    <el-carousel-item>
      <div class="swiper-item swiper-item-3">
        <img ref="img2" class="swiper-img" src="@/assets/images/swiper/swiperitem02.jpg" alt />
        <div class="item-text">
          <h1>软件服务外包</h1>
          <ul>
            <li>各类软件开发的人员外包</li>
            <li>项目外包 服务外包</li>
          </ul>
        </div>
      </div>
    </el-carousel-item>
    <el-carousel-item>
      <div class="swiper-item swiper-item-4">
        <img ref="img3" class="swiper-img" src="@/assets/images/swiper/swiperitem04.jpg" alt />
        <div class="item-text">
          <h1>定制服务</h1>
          <ul>
            <li>实现定制开发</li>
            <li>自由扩展、安全可靠</li>
          </ul>
        </div>
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  name: "Swiper",
  data(){
    return {
      height: '400px'
    }
  },
  computed: {
    imgHeight(){
      return this.$refs.img.clientHeight + 'px'
    }
  },
  methods: {
    resizeSwiper(){
      let imgs = [this.$refs.img, this.$refs.img1, this.$refs.img2, this.$refs.img3]
      imgs.forEach(item => {
        item.style.height = 400 * (document.body.clientWidth / 1439) + 'px'
      })
      this.height = this.$refs.img.style.height
    }
  },
  mounted(){
    this.$nextTick(()=>{
      window.addEventListener("resize", this.resizeSwiper)
    })
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeSwiper)
  }
};
</script>

<style scoped>
.swiper {
  position: relative;
  overflow: hidden;
  height: auto;
  width: 100%;
}

.swiper .swiper-item {
  height: 100%!important;
}

.swiper .swiper-item .item-text {
  position: absolute;
  color: rgb(47,62,80);
}
.swiper .swiper-item .swiper-img {
  width: 100%;
  height: 400px;
}
.swiper .swiper-item-1 .item-text {
  top: 20%;
  left: 25%;
}

.swiper .swiper-item-2 .item-text {
  top: 20%;
  right: 25%;
}

.swiper .swiper-item-3 .item-text {
  top: 20%;
  left: 25%;
}

.swiper .swiper-item-4 .item-text {
  top: 20%;
  right: 25%;
}

.swiper .item-text ul li {
  margin: 0 0 10px 0;
  font-size: 18px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 350px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
