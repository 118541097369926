<template>
    <el-menu id="menu" :router="true" class="menu nav-bar" mode="horizontal" :default-active="activeRoute" menu-trigger="click" active-text-color="#fff" @select="handleSelect">
        <el-menu-item class="nav-bar-item margin-lg" index="/home">首页</el-menu-item>
        <el-menu-item class="nav-bar-item margin-lg" index="/services">服务范围</el-menu-item>
        <el-menu-item class="nav-bar-item margin-lg" index="/projects">客户案例</el-menu-item>
        <el-menu-item class="nav-bar-item margin-lg" index="/advance">版图优势</el-menu-item>
        <el-menu-item class="nav-bar-item margin-lg" index="/about">关于我们</el-menu-item>
        <!-- <el-menu-item class="nav-bar-item-last">
            <a class="outer-link" href="http://manage.domain-sz.com/" target="_blank">管理平台</a>
        </el-menu-item> -->
    </el-menu>
</template>

<script>
    export default {
        name: "NavBar",
        computed: {
            activeRoute(){
                return this.$route.matched[0] && this.$route.matched[0].path
            }
        },
        methods: {
            handleSelect(){
            }
        }
    }
</script>

<style scoped>
.menu .nav-bar-item {
    height: 30px !important;
    line-height: 30px;
}
.el-menu--horizontal>.el-menu-item {
    color: #000;
}
.el-menu.el-menu--horizontal {
    padding: 25px 0;
    height: 40px;
    border-bottom: none;
}

.el-menu.el-menu--horizontal .el-menu-item{
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    padding: 6px 12px 8px;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    border-bottom: 0;
}

.menu .is-active {
    background-color: rgb(41,146,208) !important;
}

.menu .nav-bar-item-last {
    padding: 0 !important;
    height: 44px !important;
}

.menu .nav-bar-item-last .outer-link {
    padding: 6px 12px 8px;
    display: block;
    height: 30px;
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    transition: background-color ease .3s;
}

.menu .nav-bar-item:hover,
.menu .nav-bar-item-last .outer-link:hover {
    text-decoration: none;
    color: #fff !important;
    border-radius: 5px;
    background-color: rgb(41,146,208) !important;
}

.nav-bar .margin-lg {
    margin-right: 50px;
}
</style>
