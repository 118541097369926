<template>
  <div id="app">
    <el-container class="container">
      <el-header class="header" height="auto">
        <el-row type="flex" justify="space-between">
          <!-- 版图logo -->
          <div class="logo">
            <h1>
              <a href="javascript:;" @click="handleClick">
                <img src="@/assets/images/domainlogo.png" alt="logo" />
              </a>
            </h1>
          </div>
          <!-- 顶部导航栏 -->
          <nav-bar />
        </el-row>
      </el-header>
      <el-main class="main">
        <swiper v-show="isShow" />
        <banner v-show="!isShow" />
        <router-view />
      </el-main>
      <el-footer class="footer" height="auto">
        <footer-info />
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import NavBar from "@/components/content/NavBar";
import FooterInfo from "@/components/content/FooterInfo";
import Swiper from "@/components/common/Swiper"
import Banner from "@/components/content/Banner"
export default {
  name: "App",
  data(){
    return {
      currentPath: "",
    }
  },
  components: {
    NavBar,
    FooterInfo,
    Banner,
    Swiper
  },
  watch: {
    $route(newVal){
      this.currentPath = newVal.path
    }
  },
  computed: {
    isShow(){
      return this.currentPath === '/home' ? true : false
    }
  },
  methods: {
    handleClick() {
      this.$router.push("/home");
    }
  }
};
</script>

<style>
@import url("./assets/css/base.css");
@import url("./assets/css/media.css");

#app .container .header {
  padding: 0 100px;
}

#app .row-flex {
  flex-wrap: wrap;
}

#app .logo {
  width: 200px;
}

#app .logo h1 {
  margin: 0;
}

#app .logo a {
  display: block;
  text-decoration: none;
}

#app .logo a img {
  padding: 10px 0;
  vertical-align: middle;
  width: 200px;
}

#app .main {
  padding: 0;
}

#app .footer {
  position: relative;
  bottom: 0;
  left: 0;
  padding: 0;
}
</style>
