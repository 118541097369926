<template>
  <el-row class="footer-info" type="flex" justify="center">
    <el-col :span="20">
      <el-row>
          <el-row type="flex" justify="center" class="row-flex footer-head">
              <el-col class="footer-col"><h1 class="contact-us">联系我们</h1></el-col>
          </el-row>
          <el-row type="flex" justify="space-between" class="row-flex detail-bd">
              <el-col :xs="24" :sm="4" :md="4" v-for="(item, index) in details" :key="index">
                  <router-link class="details" to="/home">
                      <div class="img-area"><img class="item-img" :src="item.img" alt=""></div>
                      <strong class="item-key">{{item.key}}</strong>
                      <p class="item-value">{{item.value}}</p>
                  </router-link>
              </el-col>
          </el-row>
      </el-row>
      <el-row type="flex" justify="center" class="row-flex">
        <el-col :span="24">
          <el-divider color="#fff" class="footer-divide"></el-divider>
        </el-col>
        <el-col :xs="15" :sm="18" :md="20" class="copyright">
          Copyright © 2020 苏州版图网络科技有限公司 ICP备案：
          <a class="backup" href="http://beian.miit.gov.cn" target="_blank">苏ICP备18000116号</a>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "FooterInfo",
  data() {
    return {
      details: [
        {
          img: require("@/assets/images/footer/address.png"),
          key: "地址",
          value: "江苏省苏州创意产业园区超擎大厦202",
        },
        {
          img: require("@/assets/images/footer/tel.png"),
          key: "联系方式",
          value: "13584874863",
        },
        {
          img: require("@/assets/images/footer/email.png"),
          key: "邮箱",
          value: "daocheng.li@domain-sz.com",
        },
      ],
    };
  }
};
</script>

<style scoped>
.footer-info {
  padding: 50px 0 0;
  height: 100%;
  color: #fff;
  background-color: rgb(47, 62, 80);
}

.footer-info .footer-head {
    margin-bottom: 40px;
}

.footer-info .detail-bd {
    margin-bottom: 50px;
}

.footer-info .contact-us {
  position: relative;
  margin-top: 0;
  font-size: 28px;
  font-weight: 400;
}

.footer-info .contact-us::after {
    position: absolute;
    bottom: -15px;
    left: 50%;
    margin-left: -15px;
    content: "";
    width: 30px;
    height: 3px;
    background-color: #8ec441;
}

.footer-info .row-flex .contact-desc {
  font-size: 14px;
  color: rgba(255,255,255,.5)
}

.footer-info .row-flex {
  flex-wrap: wrap;
}

.footer-info .row-flex .footer-col {
  display: flex;
  justify-content: center;
}

.footer-info .details {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
    /*border-bottom: 2px solid #8ec441;*/
    transition: transform ease .5s;
}

.footer-info .details:hover {
    text-decoration: none;
    transform: translateY(-10px);
}

.footer-info .img-area {
    position: relative;
    width: 120px;
    height: 120px;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

.footer-info .item-img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px;
    width: 30px;
}

.footer-info .item-key {
    display: block;
    margin: 30px 0 22px;
    font-size: 14px;
}

.footer-info .item-value {
    margin-top: 0;
    font-size: 14px;
    text-align: center;
}

.footer-info .sub-nav {
  padding: 0;
  list-style: none;
}

.footer-info .sub-nav-item {
  display: block;
  float: left;
  padding: 0 15px 0 0;
}

.footer-info .footer-divide {
  margin: 0 0 5px;
}

.footer-info .copyright {
  padding: 12px 0 20px;
  text-align: center;
  color: #fff;
  font-size: 13px;
}

.footer-info .backup {
  color: rgb(57, 111, 180);
}

.footer-info .contact-detail,
.footer-info .web-map {
  margin-bottom: 10px;
}

</style>
