<template>
    <el-row class="banner banner-bg">
        <el-row type="flex" justify="center">
            <el-col :span="12" class="banner-col">
                <img src="@/assets/images/banner/domain.png" alt="">
                <h1 class="banner-title show-lg-and-up">未来与你同行</h1>
                <h1 class="show-lg-and-down">未来与你同行</h1>
                <p>featured clients</p>
            </el-col>
        </el-row>
    </el-row>
</template>

<script>
    export default {
        name: "Banner"
    }
</script>

<style scoped>
    .banner {
        text-align: center;
    }

    .banner::after {
        position: absolute;
        top: 0;
        content: "";
        width: 100%;
        height: 200px;
        background-color: #475669;
        opacity: .2;
    }

    .banner-bg {
        height: 200px;
        background: url("../../assets/images/banner/banner.jpg") no-repeat 50% 32%;
    }

    .banner-bg img {
        width: 200px;
    }

    .banner .banner-col {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 200px;
        text-align: center;
        z-index: 9
    }

    .banner .banner-col h1 {
        margin: 10px 0 0;
        font-size: 30px;
        font-weight: 400;
        color: #fff;
    }

    .banner .banner-col .banner-title {
        position: relative;
        margin: 10px 0 0;
        width: 100%;
        font-size: 30px;
        font-weight: 400;
        color: #fff;
    }

    .banner .banner-col .banner-title::before {
        display: block;
        position: absolute;
        top: 50%;
        left: 15%;
        content: "";
        width: 100px;
        height: 1px;
        border-bottom: 1px solid #fff;
    }

    .banner .banner-col .banner-title::after {
        display: block;
        position: absolute;
        top: 50%;
        right: 15%;
        content: "";
        width: 100px;
        height: 1px;
        border-bottom: 1px solid #fff;
    }

    .banner p {
        color: #fff;
        font-size: 15px;
    }


</style>
