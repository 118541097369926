import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import('@/views/Home')
const Services = () => import('@/views/Services')
const Projects = () => import('@/views/projects/Projects')
const About = () => import('@/views/About')
const Advance = () => import('@/views/Advance')
const Error = () => import('@/components/common/Error')

const ProjectDetail = () => import("@/views/projects/childrenViews/ProjectDetail")

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/services',
    name: 'services',
    component: Services
  },
  {
    path: '/projects',
    component: Projects,
    children: [
      {
        path: '',
        redirect: 'project-detail/1'
      },
      {
        path: 'project-detail/:id',
        name: 'project-detail',
        component: ProjectDetail
      }
    ]
  },
  {
    path: '/advance',
    name: 'advance',
    component: Advance
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '*',
    name: 'error',
    component: Error
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
